import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import './../Gorodki/Gorodki.css';
import Footer from './../../components/Footer/Footer';
import galleryImage1 from './../../images/cunnimagic/kyn1.jpg';
import galleryImage2 from './../../images/cunnimagic/kyn2.jpg';
import galleryImage3 from './../../images/cunnimagic/kyn3.jpg';
import galleryImage4 from './../../images/cunnimagic/kyn4.jpg';
import galleryImage5 from './../../images/cunnimagic/kyn5.jpg';
import galleryImage6 from './../../images/cunnimagic/kyn6.png';
import galleryImage7 from './../../images/cunnimagic/kyn7.png';
import galleryImage8 from './../../images/cunnimagic/kyn8.png';
import QrCodeSection from './../QrCodeSection';

const Kyn = () => {
  // Первое множество изображений для первой галереи
  const firstGalleryImages = [galleryImage1, galleryImage2, galleryImage3, galleryImage6, galleryImage7, galleryImage8];

  // Второе множество изображений для второй галереи
  const secondGalleryImages = [galleryImage4, galleryImage5];

  const [currentIndex1, setCurrentIndex1] = useState(1); // Начинаем с 1 для бесконечного слайдера
  const [currentIndex2, setCurrentIndex2] = useState(1); // Начинаем с 1 для бесконечного слайдера

  // Обработчик свайпа для первой галереи
  const swipeHandlers1 = useSwipeable({
    onSwipedLeft: () => handleNext1(),
    onSwipedRight: () => handlePrev1(),
    trackMouse: true,
  });

  // Обработчик свайпа для второй галереи
  const swipeHandlers2 = useSwipeable({
    onSwipedLeft: () => handleNext2(),
    onSwipedRight: () => handlePrev2(),
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext1(); // Автопереключение для первой галереи
    }, 25000);
    return () => clearInterval(interval);
  }, [currentIndex1]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext2(); // Автопереключение для второй галереи
    }, 25000);
    return () => clearInterval(interval);
  }, [currentIndex2]);

  const handleNext1 = () => {
    setCurrentIndex1((prevIndex) => (prevIndex + 1) % (firstGalleryImages.length + 2));
  };

  const handlePrev1 = () => {
    setCurrentIndex1((prevIndex) => (prevIndex - 1 + (firstGalleryImages.length + 2)) % (firstGalleryImages.length + 2));
  };

  const handleNext2 = () => {
    setCurrentIndex2((prevIndex) => (prevIndex + 1) % (secondGalleryImages.length + 2));
  };

  const handlePrev2 = () => {
    setCurrentIndex2((prevIndex) => (prevIndex - 1 + (secondGalleryImages.length + 2)) % (secondGalleryImages.length + 2));
  };

  // Используем два дополнительных изображения для бесконечного эффекта
  const images1 = [firstGalleryImages[firstGalleryImages.length - 1], ...firstGalleryImages, firstGalleryImages[0]];
  const images2 = [secondGalleryImages[secondGalleryImages.length - 1], ...secondGalleryImages, secondGalleryImages[0]];

  return (
      <div>
        <div className="gorodki-container">
          {/* Крупное название и текстовое описание */}
          <h1 className="gorodki-title">Кын</h1>
          <p className="gorodki-description">
            Кын — это село в Пермском крае, которое славится своей богатой историей и активным развитием ремесел и туризма. Основанное в 1759 году, оно стало значимым центром металлургии благодаря Кыновскому железоделательному заводу, который работал более двухсот лет и обеспечивал металлом весь Урал.
            Село также известно своими археологическими находками: в его окрестностях были обнаружены пять из шести известных бронзовых блях с изображением медведя в жертвенной позе, относящихся к пермскому звериному стилю.
          </p>

          {/* Первая галерея изображений */}
          <div className="gorodki-gallery" {...swipeHandlers1}>
            <div
                className="gallery-wrapper"
                style={{ transform: `translateX(-${currentIndex1 * 100}%)`, transition: 'transform 0.5s ease-in-out' }}
            >
              {images1.map((image, index) => (
                  <div key={index} className="gallery-item">
                    <img src={image} alt={`Галерея 1 - ${index + 1}`} />
                  </div>
              ))}
            </div>
            {/* Кнопки для переключения */}
            <div className="gallery-buttons">
              <button className="gallery-button" onClick={handlePrev1}>‹</button>
              <button className="gallery-button" onClick={handleNext1}>›</button>
            </div>
          </div>

          {/* Описание для первой галереи */}
          <p className="gorodki-description">
            Природа вокруг Кына впечатляет живописными ландшафтами, включая знаменитые скалы Великан, Печка и Денежный на реке Чусовой. Эти места привлекают туристов и любителей активного отдыха, предоставляя возможности для пеших прогулок и фотосъемки.
          </p>

          {/* Вторая галерея */}
          <div className="gorodki-gallery" {...swipeHandlers2}>
            <div
                className="gallery-wrapper"
                style={{ transform: `translateX(-${currentIndex2 * 100}%)`, transition: 'transform 0.5s ease-in-out' }}
            >
              {images2.map((image, index) => (
                  <div key={index} className="gallery-item">
                    <img src={image} alt={`Галерея 2 - ${index + 1}`} />
                  </div>
              ))}
            </div>
            {/* Кнопки для переключения */}
            <div className="gallery-buttons">
              <button className="gallery-button" onClick={handlePrev2}>‹</button>
              <button className="gallery-button" onClick={handleNext2}>›</button>
            </div>
          </div>

          {/* Описание для второй галереи */}
          <p className="gorodki-description">
            В Кыну активно развиваются различные ремесла, такие как лесозаготовка и производство мебели. Местные жители занимаются также рукоделием, создавая уникальные изделия из дерева, текстиля и глины.
          </p>

          <QrCodeSection />
        </div>
        <Footer />
      </div>
  );
};

export default Kyn;




